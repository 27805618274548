<template>
    <el-tree ref='resource' :data="treeData" node-key="id" default-expand-all @node-drop="handleDrop" draggable
        :allow-drag="allowDrag">
        <template #default="{node, data}">
            <span>{{ node.label }} &nbsp;</span>
            <el-button icon='el-icon-edit' type="text" class="button" v-if='data.info.parentId != 0'
                @click.stop='openNode("edit", node, data)'> </el-button>
            <el-button icon='el-icon-circle-plus-outline' type="text" class="button"
                @click.stop='openNode("add", node, data)'> </el-button>
        </template>
    </el-tree>

    <FormDialog :title='title' :visible='visible' :form='selected' @confirm='addResource' @close='closeDialog'>
        <template #formRegion='scope'>
            <span style='display:none'>{{scope.form.rsName}}</span>
            <!-- <el-form :model="scope.form"> -->
            <el-form label-position="left" size="mini" ref="formref" status-icon label-width="80px" :model="scope.form">
                <el-form-item label="上级资源">
                    <el-input v-model="scope.form.parentName" disabled></el-input>
                </el-form-item>
                <el-form-item label="资源编码" prop='rsCode'>
                    <el-input v-model="scope.form.rsCode" :disabled='scope.form.id > 0'></el-input>
                </el-form-item>
                <el-form-item label="资源名称" prop='rsName'>
                    <el-input clearable v-model="scope.form.rsName"></el-input>
                </el-form-item>
                <el-form-item label="资源描述">
                    <el-input clearable v-model="scope.form.rsDesc"></el-input>
                </el-form-item>
            </el-form>
        </template>
    </FormDialog>
</template>
<script>
    import { resourceTree, resourceAdd, resourceMove } from '@/utils/api'
    import { getPathVariableAppKey } from '@/utils/tools'
    import { ref, reactive, provide } from 'vue'
    import FormDialog from '@/components/FormDialog'

    export default {
        components: { FormDialog },
        setup() {
            const appkey = getPathVariableAppKey()
            let treeData = ref([])

            let title = ref('新增角色')
            let visible = ref(false)
            let selected = reactive(initSelected())

            //搜索资源树
            function search() {
                resourceTree(appkey).then(res => {
                    treeData.value = [res.data]
                    this.flush()
                })
            }

            function openEmptyDialog() {
                visible.value = true
                selected.id = 0,
                    selected.parentId = 0,
                    selected.parentName = '',
                    selected.rsCode = '',
                    selected.rsName = '',
                    selected.rsDesc = ''
            }
            function closeDialog() {
                visible.value = false
            }

            // 删除角色
            function openNode(key, node, data) {
                visible.value = true
                title.value = key == 'add' ? '新增子资源' : '编辑资源'
                selected.id = key == 'edit' ? data.id : 0
                selected.parentId = key == 'edit' ? node.parent.data.id : data.id
                selected.parentName = key == 'edit' ? node.parent.data.label : data.label
                selected.rsCode = key == 'edit' ? data.info.rsCode : ''
                selected.rsName = key == 'edit' ? data.info.rsName : ''
                selected.rsDesc = key == 'edit' ? data.info.rsDesc : ''
            }
            function initSelected() {
                return {
                    id: 0,
                    parentId: 0,
                    parentName: '',
                    rsCode: '',
                    rsName: '',
                    rsDesc: ''
                }
            }

            //移动节点
            function handleDrop(from, to) {
                resourceMove(appkey, to.data.id, from.data.id).then(res => {
                    search()
                })
            }
            //是否可以移动
            function allowDrag(node) {
                return node.data.info.parentId != 0;
            }

            return {
                appkey,
                treeData,
                title,
                visible,
                selected,

                search,
                openNode,
                closeDialog,

                handleDrop,
                allowDrag,

                formRules: {
                    roleCode: [
                        { required: true, message: '请输入角色编码', trigger: 'change' },
                    ],
                    roleName: [
                        { required: true, message: '请输入角色名称', trigger: 'change' },
                    ]
                },
            }

        },
        methods: {
            addResource(form) {
                let _this = this
                this.$refs['formref'].validate((valid) => {
                    if (valid) {
                        let request = {
                            id: form.id,
                            parentId: form.parentId,
                            rsCode: form.rsCode,
                            rsName: form.rsName,
                            rsDesc: form.rsDesc,
                        }
                        _this.closeDialog()
                        resourceAdd(_this.appkey, request).then(res => {
                            _this.search()
                        })
                    } else {
                        return false;
                    }
                });
            },
            flush() {
                this.$refs['resource'].$forceUpdate()
            }
        },
        mounted() {
            this.search()
        }
    }
</script>